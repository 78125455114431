import * as actionTypes from './actionTypes';
import { OrderActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Order } from '../../domain/Order';

export type OrderStateType = {
  ordersList: ListResults<Order> | null;
  ordersLoading: boolean;
  ordersError: HttpError;
  ordersListUpdateNeeded: boolean;
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes;
};

export const initialState: OrderStateType = {
  ordersList: null,
  ordersLoading: false,
  ordersError: null,
  ordersListUpdateNeeded: false,
};

const fetchOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  ordersLoading: true,
});

const fetchOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersList: action.ordersList,
  ordersLoading: false,
  ordersError: null,
  ordersListUpdateNeeded: false,
});

const fetchOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersError: action.ordersError,
  ordersLoading: false,
});

const resetOrderStore = (): OrderStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action);
    case actionTypes.RESET_ORDER_STORE:
      return resetOrderStore();
    default:
      return state;
  }
};

export default reducer;
