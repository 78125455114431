import React, { useEffect, useMemo, useState } from 'react';
import styles from './RoleSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { selectRole } from '../../store/auth/actions';
import { Roles } from '../../domain/Role';
import { User } from '../../domain/User';
import * as userSettingService from '../../store/user-setting/service';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';

type Props = {
  onRoleSelect: (role: Roles) => void;
  selectedRole: Roles | null;
  currentUser: User | null;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
};

const RoleSwitcher = ({
  onRoleSelect,
  selectedRole,
  currentUser,
  onSetSetting,
  createdUserSetting,
}: Props) => {
  const intl = useIntl();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const roles = useMemo(
    () => currentUser?.roles.map((role) => role.name) ?? [],
    [currentUser],
  );

  useEffect(() => {
    if (selectedRole || !roles.length) {
      return;
    }

    if (roles.includes(Roles.ADMIN)) {
      handleClick(Roles.ADMIN);
    } else {
      handleClick(roles[roles.length - 1]);
    }
  }, [roles]);

  useEffect(() => {
    if (createdUserSetting?.type === UserSettingType.ROLE) {
      onRoleSelect(createdUserSetting.value as Roles);
      setIsLoading(false);
    }
  }, [createdUserSetting]);

  const handleClick = (role: Roles) => {
    if (selectedRole === role || isLoading) {
      setIsPopoverOpen(false);

      return;
    }

    onSetSetting(UserSettingType.ROLE, role);
    setIsLoading(true);
    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return currentUser?.roles.map((role, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(role.name)}
      >
        {translate(intl, `USERS.ROLE_${role.name}`)}
      </button>
    ));
  };

  if (
    !currentUser ||
    currentUser.roles.length <= 1 ||
    roles.includes(Roles.ADMIN)
  ) {
    return null;
  }

  return (
    <>
      <div
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedRole && translate(intl, `USERS.ROLE_${selectedRole}`)}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedRole: state.auth.selectedRole,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onRoleSelect: (role: Roles) => dispatch(selectRole(role)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleSwitcher);
