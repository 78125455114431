export const routes = {
  homepage: '/:locale/admin',
  login: '/:locale/login',
  register: '/:locale/register/:code?',
  registrationConfirmation: '/:locale/registration-confirmation/:code',
  remind: '/:locale/remind-password',
  resetPassword: '/:locale/password-reset/:code',
  profile: '/:locale/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/:locale/admin',
  users: {
    list: '/:locale/admin/users',
    create: '/:locale/admin/users/new',
    edit: '/:locale/admin/users/:id',
    profile: '/:locale/admin/profile',
  },
  companies: {
    list: '/:locale/admin/companies',
    edit: '/:locale/admin/companies/:id',
    create: '/:locale/admin/companies/create',
    instructions: '/:locale/admin/company/instructions',
    risks: '/:locale/admin/company/risks',
    actionPlans: '/:locale/admin/company/action-plans',
    actionPlanProtocol: '/:locale/admin/company/action-plan/:id/protocol',
  },
  courses: {
    list: '/:locale/admin/courses',
    edit: '/:locale/admin/courses/:id',
    create: '/:locale/admin/courses/create',
    shop: '/:locale/admin/shop',
    details: '/:locale/admin/shop/:id',
    myDetails: '/:locale/admin/my-course/:id',
    test: '/:locale/admin/test/:id',
  },
  employees: {
    list: '/:locale/admin/employees',
    edit: '/:locale/admin/employees/:id',
    create: '/:locale/admin/employees/create',
    instructions: '/:locale/admin/instructions',
    actionPlans: '/:locale/admin/action-plans',
    accidents: '/:locale/admin/accidents',
  },
  companyTypes: {
    list: '/:locale/admin/company-types',
  },
  jobTitles: {
    list: '/:locale/admin/job-titles',
  },
  instructions: {
    list: '/:locale/admin/instructions',
  },
  meetings: {
    list: '/:locale/admin/meetings',
  },
  risks: {
    list: '/:locale/admin/risks',
  },
  accidents: {
    list: '/:locale/admin/accidents',
  },
  clients: {
    list: '/:locale/admin/clients',
  },
  projects: {
    list: '/:locale/admin/projects',
  },
  workLogs: {
    list: '/:locale/admin/work-logs',
  },
  orders: {
    list: '/:locale/admin/orders',
  },
  shop: {
    hms: '/:locale/admin/shop-hms',
  },
  export: '/:locale/admin/export',
  translations: '/:locale/admin/translations',
  purchase: '/purchase',
};
