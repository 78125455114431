import axios from '../../config/Axios/axios-instance';
import {
  logout,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFail,
  loginStart,
  loginSuccess,
  loginFail,
  registrationStart,
  registrationSuccess,
  registrationFail,
  registerConfirmStart,
  registerConfirmSuccess,
  registerConfirmFail,
  remindStart,
  remindSuccess,
  remindFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  validateCodeStart,
  validateCodeSuccess,
  validateCodeFail,
  validateInvitationCodeStart,
  validateInvitationCodeSuccess,
  validateInvitationCodeFail,
  confirmInvitationStart,
  confirmInvitationSuccess,
  confirmInvitationFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
} from './actions';
import { Dispatch } from 'redux';
import { Locale } from '../../domain/Translation';
import { resetCourseStore } from '../course/actions';
import { resetCompanyStore } from '../company/actions';
import { resetUserSettingStore } from '../user-setting/actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/auth';

export type LoginRequest = {
  email: string;
  password: string;
};

export type RegistrationRequest = {
  email?: string;
  password: string;
  firstName?: string;
  lastName?: string;
  locale: Locale;
};

export type RemindRequest = {
  email: string;
  locale: Locale;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};

export type ConfirmRegistrationRequest = {
  code: string;
};

export type InviteRegisterRequest = {
  password: string;
  code: string;
};

export type ChangePasswordRequest = {
  password: string;
  confirmPassword: string;
};

export const login = (inputs: LoginRequest) => (dispatch: Dispatch) => {
  dispatch(loginStart());
  return axios
    .post(`${API_URL}/login`, {
      ...inputs,
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(loginSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(loginFail(err.response.data.message));
    });
};

export const register =
  (inputs: RegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registrationStart());
    return axios
      .post(`${API_URL}/register`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(registrationSuccess());
      })
      .catch((err) => {
        dispatch(registrationFail(err.response.data.message));
      });
  };

export const refreshToken = () => (dispatch: Dispatch) => {
  dispatch(refreshTokenStart());
  return axios
    .post(`${API_URL}/refresh`)
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(refreshTokenSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(refreshTokenFail(err.response.data.message));
    });
};

export const confirmRegistration =
  (inputs: ConfirmRegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registerConfirmStart());
    return axios
      .post(`${API_URL}/confirm-registration`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(registerConfirmSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(registerConfirmFail(err.response.data.message));
      });
  };

export const remind = (inputs: RemindRequest) => (dispatch: Dispatch) => {
  dispatch(remindStart());
  return axios
    .post(`${API_URL}/remind`, {
      ...inputs,
    })
    .then(async (response) => {
      dispatch(remindSuccess());
    })
    .catch((err) => {
      dispatch(remindFail(err.response.data.message));
    });
};

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios
      .patch(`${API_URL}/reset-password`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(resetPasswordSuccess());
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err.response.data.message));
      });
  };

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios
      .post(`${API_URL}/validate-confirmation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err.response.data.message));
      });
  };

export const validateInvitationCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateInvitationCodeStart());
    return axios
      .post(`${API_URL}/validate-invitation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateInvitationCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateInvitationCodeFail(err.response.data.message));
      });
  };

export const confirmInvitation =
  (inputs: InviteRegisterRequest) => (dispatch: Dispatch) => {
    dispatch(confirmInvitationStart());
    return axios
      .patch(`${API_URL}/confirm-invitation`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(confirmInvitationSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(confirmInvitationFail(err.response.data.message));
      });
  };

export const changePassword =
  (inputs: ChangePasswordRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(changePasswordStart());
    return axios
      .post(`${API_URL}/change-password`, {
        ...inputs,
      })
      .then(() => {
        dispatch(changePasswordSuccess());
        showToast(translate(intl, 'USERS.SUCCESS_PASSWORD_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(changePasswordFail(err.response.data.message));
      });
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(refreshTokenSuccess(token)) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  localStorage.removeItem('companyLogo');
  localStorage.removeItem('selectedRole');
  localStorage.removeItem('availableSystems');
  localStorage.removeItem('selectedCompany');
  localStorage.removeItem('PurchasePlan');
  localStorage.removeItem('PurchaseCourse');
  dispatch(resetCompanyStore());
  dispatch(resetCourseStore());
  dispatch(resetUserSettingStore());
  dispatch(logout());
};
