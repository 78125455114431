export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const RESET_ORDER_STORE = 'RESET_ORDER_STORE';

export type OrderActionTypes =
  | typeof FETCH_ORDERS_START
  | typeof FETCH_ORDERS_SUCCESS
  | typeof FETCH_ORDERS_FAIL
  | typeof RESET_ORDER_STORE;
