import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import courseReducer, { CourseStateType } from '../../store/course/reducer';
import sectionReducer, { SectionStateType } from '../../store/section/reducer';
import lessonReducer, { LessonStateType } from '../../store/lesson/reducer';
import companyTypeReducer, {
  CompanyTypeStateType,
} from '../../store/company-type/reducer';
import questionReducer, {
  QuestionStateType,
} from '../../store/question/reducer';
import answerReducer, { AnswerStateType } from '../../store/answer/reducer';
import instructionReducer, {
  InstructionStateType,
} from '../../store/instruction/reducer';
import instructionGroupReducer, {
  InstructionGroupStateType,
} from '../../store/instruction-group/reducer';
import courseCertificateReducer, {
  CourseCertificateStateType,
} from '../../store/course-certificate/reducer';
import companyInstructionReducer, {
  CompanyInstructionStateType,
} from '../../store/company-instruction/reducer';
import companyInstructionGroupReducer, {
  CompanyInstructionGroupStateType,
} from '../../store/company-instruction-group/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import jobTitleReducer, {
  JobTitleStateType,
} from '../../store/job-title/reducer';
import meetingReducer, { MeetingStateType } from '../../store/meeting/reducer';
import riskReducer, { RiskStateType } from '../../store/risk/reducer';
import riskGroupReducer, {
  RiskGroupStateType,
} from '../../store/risk-group/reducer';
import companyMeetingReducer, {
  CompanyMeetingStateType,
} from '../../store/company-meeting/reducer';
import companyRiskReducer, {
  CompanyRiskStateType,
} from '../../store/company-risk/reducer';
import companyRiskGroupReducer, {
  CompanyRiskGroupStateType,
} from '../../store/company-risk-group/reducer';
import accidentReducer, {
  AccidentStateType,
} from '../../store/accident/reducer';
import employeeInstructionReducer, {
  EmployeeInstructionsStateType,
} from '../../store/employee-instruction/reducer';

import employeeMeetingReducer, {
  EmployeeMeetingsStateType,
} from '../../store/employee-meeting/reducer';

import employeeAccidentReducer, {
  EmployeeAccidentsStateType,
} from '../../store/employee-accident/reducer';

import clientReducer, { ClientStateType } from '../../store/client/reducer';
import projectReducer, { ProjectStateType } from '../../store/project/reducer';
import workLogReducer, { WorkLogStateType } from '../../store/work-log/reducer';
import orderReducer, { OrderStateType } from '../../store/order/reducer';
import userSettingReducer, {
  UserSettingStateType,
} from '../../store/user-setting/reducer';

import hmsPackageReducer, {
  HmsPackageStateType,
} from '../../store/hmsPackage/reducer';

import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import { resetCourseStore } from '../../store/course/actions';
import { resetCompanyStore } from '../../store/company/actions';
import { resetUserSettingStore } from '../../store/user-setting/actions';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  company: CompanyStateType;
  course: CourseStateType;
  employee: EmployeeStateType;
  section: SectionStateType;
  lesson: LessonStateType;
  courseCertificate: CourseCertificateStateType;
  question: QuestionStateType;
  answer: AnswerStateType;
  companyType: CompanyTypeStateType;
  jobTitle: JobTitleStateType;
  instruction: InstructionStateType;
  instructionGroup: InstructionGroupStateType;
  companyInstruction: CompanyInstructionStateType;
  companyInstructionGroup: CompanyInstructionGroupStateType;
  meeting: MeetingStateType;
  companyMeeting: CompanyMeetingStateType;
  risk: RiskStateType;
  riskGroup: RiskGroupStateType;
  companyRisk: CompanyRiskStateType;
  companyRiskGroup: CompanyRiskGroupStateType;
  accident: AccidentStateType;
  employeeInstruction: EmployeeInstructionsStateType;
  employeeMeeting: EmployeeMeetingsStateType;
  employeeAccident: EmployeeAccidentsStateType;
  client: ClientStateType;
  project: ProjectStateType;
  workLog: WorkLogStateType;
  userSetting: UserSettingStateType;
  order: OrderStateType;
  hmsPackage: HmsPackageStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  company: companyReducer,
  course: courseReducer,
  employee: employeeReducer,
  section: sectionReducer,
  lesson: lessonReducer,
  courseCertificate: courseCertificateReducer,
  question: questionReducer,
  answer: answerReducer,
  companyType: companyTypeReducer,
  jobTitle: jobTitleReducer,
  instruction: instructionReducer,
  instructionGroup: instructionGroupReducer,
  companyInstruction: companyInstructionReducer,
  companyInstructionGroup: companyInstructionGroupReducer,
  meeting: meetingReducer,
  companyMeeting: companyMeetingReducer,
  risk: riskReducer,
  riskGroup: riskGroupReducer,
  companyRisk: companyRiskReducer,
  companyRiskGroup: companyRiskGroupReducer,
  accident: accidentReducer,
  employeeInstruction: employeeInstructionReducer,
  employeeMeeting: employeeMeetingReducer,
  employeeAccident: employeeAccidentReducer,
  client: clientReducer,
  project: projectReducer,
  workLog: workLogReducer,
  userSetting: userSettingReducer,
  order: orderReducer,
  hmsPackage: hmsPackageReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      localStorage.removeItem('availableSystems');
      localStorage.removeItem('selectedSystem');
      localStorage.removeItem('selectedRole');
      dispatch(resetCompanyStore());
      dispatch(resetCourseStore());
      dispatch(resetUserSettingStore());
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
