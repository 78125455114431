import { Roles } from '../../domain/Role';
import { CompanySystem } from '../../domain/Company';
import { NavigationItem } from '../../common/Layout/Layout';

export const isVisibleRoute = (
  selectedRole: Roles | null,
  availableSystems: CompanySystem[] | null,
  system: CompanySystem | undefined,
) => {
  if (!selectedRole) {
    return false;
  }

  if (selectedRole === Roles.ADMIN) {
    return true;
  }

  return !system || availableSystems?.includes(system);
};

export const isVisibleLink = (
  selectedRole: Roles | null,
  availableSystems: CompanySystem[] | null,
  item: NavigationItem,
) => {
  if (!selectedRole) {
    return false;
  }

  return (
    item.roles.includes(selectedRole) &&
    (!item.system || availableSystems?.includes(item.system))
  );
};
