import React from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { User } from '../../domain/User';
import RoleSwitcher from '../RoleSwitcher/RoleSwitcher';
import { useNavigate } from 'react-router-dom';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Locale } from '../../domain/Translation';
import { routes } from '../../config/Router/routes';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  currentUser: User | null;
  selectedLocale: Locale;
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  currentUser,
  selectedLocale,
}: Props) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.iconItems}>
          <RoleSwitcher currentUser={currentUser} />
          <LanguageSwitcher />
          <IconButton
            onClick={() =>
              navigate(getLocaleUrl(routes.users.profile, selectedLocale))
            }
          >
            <FontAwesomeIcon icon={faUser as IconProp} fixedWidth size="sm" />
          </IconButton>
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  selectedRole: state.auth.selectedRole,
  selectedLocale: state.auth.selectedLocale,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
