import * as actionTypes from './actionTypes';
import { HmsPackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { HmsPackage } from '../../domain/HmsPackage';
import { SessionResponse } from '../../domain/Payment';

export type HmsPackageStateType = {
  hmsPackagesList: HmsPackage[] | null;
  hmsPackagesLoading: boolean;
  hmsPackagesError: HttpError;
  hmsPackagesListUpdateNeeded: boolean;
  purchaseHmsPackageLoading: boolean;
  purchaseHmsPackageSuccess: boolean;
  purchaseHmsPackageError: HttpError;
  sessionLoading: boolean;
  session: SessionResponse | null;
  sessionError: HttpError;
};

export type HmsPackageActionType = HmsPackageStateType & {
  type: HmsPackageActionTypes;
};

export const initialState: HmsPackageStateType = {
  hmsPackagesList: null,
  hmsPackagesLoading: true,
  hmsPackagesError: null,
  hmsPackagesListUpdateNeeded: false,
  purchaseHmsPackageError: null,
  purchaseHmsPackageLoading: false,
  purchaseHmsPackageSuccess: false,
  session: null,
  sessionError: null,
  sessionLoading: false,
};

const fetchHmsPackagesStart = (
  state: HmsPackageStateType,
): HmsPackageStateType => ({
  ...state,
  hmsPackagesLoading: true,
});

const fetchHmsPackagesSuccess = (
  state: HmsPackageStateType,
  action: HmsPackageActionType,
): HmsPackageStateType => ({
  ...state,
  hmsPackagesList: action.hmsPackagesList,
  hmsPackagesLoading: false,
  hmsPackagesError: null,
  hmsPackagesListUpdateNeeded: false,
});

const fetchHmsPackagesFail = (
  state: HmsPackageStateType,
  action: HmsPackageActionType,
): HmsPackageStateType => ({
  ...state,
  hmsPackagesError: action.hmsPackagesError,
  hmsPackagesLoading: false,
});

const createHmsPurchaseSessionStart = (
  state: HmsPackageStateType,
): HmsPackageStateType => ({
  ...state,
  session: null,
  sessionLoading: true,
  purchaseHmsPackageSuccess: false,
});

const createHmsPurchaseSessionSuccess = (
  state: HmsPackageStateType,
  action: HmsPackageActionType,
): HmsPackageStateType => ({
  ...state,
  session: action.session,
  sessionLoading: false,
  sessionError: null,
});

const createHmsPurchaseSessionFail = (
  state: HmsPackageStateType,
  action: HmsPackageActionType,
): HmsPackageStateType => ({
  ...state,
  sessionError: action.sessionError,
  sessionLoading: false,
});

const finalizeHmsPurchaseStart = (
  state: HmsPackageStateType,
): HmsPackageStateType => ({
  ...state,
  purchaseHmsPackageLoading: true,
  purchaseHmsPackageSuccess: false,
});

const finalizeHmsPurchaseSuccess = (
  state: HmsPackageStateType,
): HmsPackageStateType => ({
  ...state,
  purchaseHmsPackageLoading: false,
  purchaseHmsPackageError: null,
  purchaseHmsPackageSuccess: true,
});

const finalizeHmsPurchaseFail = (
  state: HmsPackageStateType,
  action: HmsPackageActionType,
): HmsPackageStateType => ({
  ...state,
  purchaseHmsPackageLoading: false,
  purchaseHmsPackageSuccess: false,
  purchaseHmsPackageError: action.purchaseHmsPackageError,
});

const resetHmsPackageStore = (): HmsPackageStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: HmsPackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_HMS_PACKAGES_START:
      return fetchHmsPackagesStart(state);
    case actionTypes.FETCH_HMS_PACKAGES_SUCCESS:
      return fetchHmsPackagesSuccess(state, action);
    case actionTypes.FETCH_HMS_PACKAGES_FAIL:
      return fetchHmsPackagesFail(state, action);
    case actionTypes.CREATE_HMS_PURCHASE_SESSION_START:
      return createHmsPurchaseSessionStart(state);
    case actionTypes.CREATE_HMS_PURCHASE_SESSION_SUCCESS:
      return createHmsPurchaseSessionSuccess(state, action);
    case actionTypes.CREATE_HMS_PURCHASE_SESSION_FAIL:
      return createHmsPurchaseSessionFail(state, action);
    case actionTypes.FINALIZE_HMS_PURCHASE_START:
      return finalizeHmsPurchaseStart(state);
    case actionTypes.FINALIZE_HMS_PURCHASE_SUCCESS:
      return finalizeHmsPurchaseSuccess(state);
    case actionTypes.FINALIZE_HMS_PURCHASE_FAIL:
      return finalizeHmsPurchaseFail(state, action);
    case actionTypes.RESET_HMS_PACKAGE_STORE:
      return resetHmsPackageStore();
    default:
      return state;
  }
};

export default reducer;
