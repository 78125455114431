import { User } from './User';
import { CompanyType } from './CompanyType';
import { ManagerHistory } from './ManagerHistory';
import { Employee } from './Employee';
import { Asset } from './Asset';

export interface Company {
  id: number;
  name: string;
  ownerId: string;
  owner: User | null;
  type: CompanyType;
  typeId: number;
  managerId: number;
  managerHistory: ManagerHistory[];
  systems: CompanySystem[];
  logo: Asset;
  managerPolicy: CompanyManagerPolicy;
}

export enum CompanySystem {
  // eslint-disable-next-line no-unused-vars
  COURSES = 1,
  // eslint-disable-next-line no-unused-vars
  HMS = 2,
  // eslint-disable-next-line no-unused-vars
  TIME_TRACKING = 3,
}

export interface ExportData {
  employees: Employee[];
}

export enum CompanyManagerPolicy {
  // eslint-disable-next-line no-unused-vars
  NO_MANAGER_ELECTIONS = 'NO_MANAGER_ELECTIONS',
  // eslint-disable-next-line no-unused-vars
  MANAGER_ELECTIONS = 'MANAGER_ELECTIONS',
}
