export const FETCH_HMS_PACKAGES_START = 'FETCH_HMS_PACKAGES_START';
export const FETCH_HMS_PACKAGES_SUCCESS = 'FETCH_HMS_PACKAGES_SUCCESS';
export const FETCH_HMS_PACKAGES_FAIL = 'FETCH_HMS_PACKAGES_FAIL';

export const CREATE_HMS_PURCHASE_SESSION_START =
  'CREATE_HMS_PURCHASE_SESSION_START';
export const CREATE_HMS_PURCHASE_SESSION_SUCCESS =
  'CREATE_HMS_PURCHASE_SESSION_SUCCESS';
export const CREATE_HMS_PURCHASE_SESSION_FAIL =
  'CREATE_HMS_PURCHASE_SESSION_FAIL';

export const FINALIZE_HMS_PURCHASE_START = 'HMS_PURCHASE_START';
export const FINALIZE_HMS_PURCHASE_SUCCESS = 'HMS_PURCHASE_SUCCESS';
export const FINALIZE_HMS_PURCHASE_FAIL = 'HMS_PURCHASE_FAIL';

export const RESET_HMS_PACKAGE_STORE = 'RESET_HMS_PACKAGE_STORE';

export type HmsPackageActionTypes =
  | typeof FETCH_HMS_PACKAGES_START
  | typeof FETCH_HMS_PACKAGES_SUCCESS
  | typeof FETCH_HMS_PACKAGES_FAIL
  | typeof CREATE_HMS_PURCHASE_SESSION_START
  | typeof CREATE_HMS_PURCHASE_SESSION_SUCCESS
  | typeof CREATE_HMS_PURCHASE_SESSION_FAIL
  | typeof FINALIZE_HMS_PURCHASE_START
  | typeof FINALIZE_HMS_PURCHASE_SUCCESS
  | typeof FINALIZE_HMS_PURCHASE_FAIL
  | typeof RESET_HMS_PACKAGE_STORE;
